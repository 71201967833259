import { createSlice } from '@reduxjs/toolkit';
import { RoleType, ScopeType } from '../../../types/user';

interface RoleState {
  permissions: ScopeType[];
  scopes: [];
  roles: RoleType[];
  role: RoleType;
}

const initialState: RoleState = {
  roles: [],
  permissions: [],
  scopes: [],
  role: <RoleType>{}
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setAllPermissions(state, action) {
      state.scopes = action.payload.results || [];
    },
    setAllRole(state, action) {
      state.roles = action.payload.results || [];
    },
    addOneRole(state, action) {
      state.roles = [...state.roles, action.payload.row];
    },
    setOneRole(state, action) {
      state.roles = state.roles.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneRole(state, action) {
      state.roles = state.roles.filter(item => item.id !== action.payload.id);
    }
  }
});

export const {
  setAllRole,
  addOneRole,
  setOneRole,
  removeOneRole,
  setAllPermissions
} = roleSlice.actions;
export default roleSlice.reducer;
