import { RouteObject } from 'react-router-dom';
import MainLayoutProvider from '../providers/MainLayoutProvider';
import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../layouts/MainLayout';
import { lazy, Suspense } from 'react';
import PhoenixLoader from '../components/common/PhoenixLoader';
import Profile from 'pages/profile';
import ChangePassword from 'pages/profile/ChangePassword';

const MyProfilePage = lazy(() => import('pages/profile/MyProfile'));

const ProfileRoutes: RouteObject = {
  path: '/profile/',
  element: (
    <MainLayoutProvider>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </MainLayoutProvider>
  ),
  children: [
    {
      path: '',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <Profile />
        </Suspense>
      ),
      children: [
        {
          path: 'my-profile',
          index: true,
          element: (
            <Suspense fallback={<PhoenixLoader />}>
              <MyProfilePage />
            </Suspense>
          )
        },
        {
          path: 'change-password',
          index: true,
          element: (
            <Suspense fallback={<PhoenixLoader />}>
              <ChangePassword />
            </Suspense>
          )
        }
      ]
    }
  ]
};

export default ProfileRoutes;
