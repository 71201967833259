import { RouteObject } from 'react-router-dom';
import MainLayoutProvider from '../providers/MainLayoutProvider';
import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../layouts/MainLayout';
import { lazy, Suspense } from 'react';
import PhoenixLoader from '../components/common/PhoenixLoader';

const ActivityTypePage = lazy(
  () => import('pages/actor-management/ActivityTypePage')
);
const ActorCategoryPage = lazy(
  () => import('pages/actor-management/ActorCategoryPage')
);
const ActorPage = lazy(() => import('pages/actor-management/ActorPage'));
const VenuePage = lazy(() => import('pages/actor-management/VenuePage'));
const BulkUpdateGradePage = lazy(
  () => import('pages/actor-management/bulk-update-grade/index')
);

const ActorManagementRoutes: RouteObject = {
  path: '/actor-management/',
  element: (
    <MainLayoutProvider>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </MainLayoutProvider>
  ),
  children: [
    {
      path: 'activity-type',
      index: true,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <ActivityTypePage />
        </Suspense>
      )
    },
    {
      path: 'actor-category',
      index: true,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <ActorCategoryPage />
        </Suspense>
      )
    },
    {
      path: 'actor',
      index: true,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <ActorPage />
        </Suspense>
      )
    },
    {
      path: 'venue',
      index: true,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <VenuePage />
        </Suspense>
      )
    },
    {
      path: 'bulk-update-grade',
      index: true,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <BulkUpdateGradePage />
        </Suspense>
      )
    }
  ]
};

export default ActorManagementRoutes;
