import { RouteObject } from 'react-router-dom';
import MainLayoutProvider from '../providers/MainLayoutProvider';
import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../layouts/MainLayout';
import { lazy, Suspense } from 'react';
import PhoenixLoader from '../components/common/PhoenixLoader';

const PatientManagementPage = lazy(
  () => import('pages/patient-management/PatientManagementPage')
);
const BulkUpload = lazy(() => import('pages/patient-management/BulkUpload'));

const PatientManagementRoute: RouteObject = {
  path: '/patient-management/',
  element: (
    <MainLayoutProvider>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </MainLayoutProvider>
  ),
  children: [
    {
      path: 'patient',
      index: true,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <PatientManagementPage />
        </Suspense>
      )
    },
    {
      path: 'bulk-upload',
      index: true,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <BulkUpload />
        </Suspense>
      )
    }
  ]
};

export default PatientManagementRoute;
