import { useDispatch, useSelector } from '../../store';
import ActivityTypeService from '../../services/actor-management/ActivityTypeService';
import { ActivityType } from '../../types/actorManagement';
import {
  addOneActivityType,
  removeOneActivityType,
  setAllActivityType,
  setOneActivityType,
  updateOneActivityType
} from '../../store/reducers/actor-mangement/acttivityTypeSlice';

const useActivityTypeHook = () => {
  const dispatch = useDispatch();
  const { activityTypes, activityType } = useSelector(
    state => state.activityType
  );

  const fetchAllActivityType = async (): Promise<ActivityType[]> => {
    try {
      const resp = await ActivityTypeService.fetchAll();
      const results = resp?.data?.data || [];
      dispatch(setAllActivityType({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllActivityType', e);
      throw e;
    }
  };
  const fetchOneActivityType = async (id: number): Promise<ActivityType> => {
    try {
      const resp = await ActivityTypeService.fetchOne(id);
      const row = resp?.data?.data || null;
      dispatch(setOneActivityType({ row }));
      return row;
    } catch (e) {
      console.log('fetchOneActivityType', e);
      throw e;
    }
  };
  const createActivityType = async (
    data: ActivityType
  ): Promise<ActivityType> => {
    try {
      const resp = await ActivityTypeService.create(data);
      const row = resp?.data?.data || null;

      dispatch(addOneActivityType({ row }));
      return row;
    } catch (e) {
      console.log('addOneActivityType', e);
      throw e;
    }
  };
  const updateActivityType = async (id: number, data: ActivityType) => {
    try {
      await ActivityTypeService.update(id, data);
      dispatch(updateOneActivityType({ id, row: data }));
    } catch (e) {
      console.log('getOneActivityType', e);
      throw e;
    }
  };
  const deleteActivityType = async (id: number) => {
    try {
      await ActivityTypeService.destroy(id);
      dispatch(removeOneActivityType({ id }));
    } catch (e) {
      console.log('getOneActivityType', e);
      throw e;
    }
  };
  return {
    fetchAllActivityType,
    createActivityType,
    deleteActivityType,
    fetchOneActivityType,
    updateActivityType,
    activityTypes,
    activityType
  };
};

export default useActivityTypeHook;
