import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {AuthTenantType} from './types/auth';
import {getAuthFromLocalStorage} from './utils/storage';

//set default NS according to tenant name
const auth = getAuthFromLocalStorage()
let defaultNS = 'translation';
try {
    const dhTenant = auth.dhTenant
    const tenant: AuthTenantType = dhTenant;
    const appEnv = process.env.REACT_APP_APP_ENV || 'local';
    if (appEnv.toLowerCase() != 'local') {
        if (tenant?.name) {
            defaultNS = `translation/${tenant?.name.toLowerCase()}`
        }
    }

    // console.log(dhTenant)
}catch (e){
    console.error(e)
}


const fallbackLng = ['en'];


// eslint-disable-next-line
i18n

    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({

        lng: 'en',
        detection: {
            order: ['cookie', 'path', 'htmlTag'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: `/assets/i18n/{{ns}}/{{lng}}.json`,
        },
        fallbackLng,
        // whitelist: availableLanguages,
        debug: true, //false to turn off console warnings


        defaultNS,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: '.',
        },
        // react: {
        //     wait: true,
        // },
        // eslint-disable-next-line
        returnEmptyString: false,
    });

export default i18n;
