import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardState } from 'types/mdm';

const initialState: DashboardState = {
  counter: {
    totalPatients: 0,
    totalEncounters: 0,
    totalTreatments: 0,
    totalPatientWithEncounters: 0
  },
  historical: {
    patients: {},
    encounters: {},
    treatments: {}
  },
  encounterByActivityType: {},
  treatmentsByTreatmentTypes: [],
  countPatientsByActorAndGender: [],
  countPatientsByGrade: [],
  patientByVenues: [],
  patientByAgeGroup: {}
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboard(
      state,
      // eslint-disable-next-line
      action: PayloadAction<{ key: keyof DashboardState; data: any }>
    ) {
      const { key, data } = action.payload;
      state[key] = data;
    }
  }
});

export const { setDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
