import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyProfile from './MyProfile';
import ChangePassword from './ChangePassword';

const Profile = () => {
  const [activeTab, setActiveTab] = useState<number>(1);

  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const page = t('page.title.profile');
  const myProfileTitle = t('common.label.my_profile');
  const changePasswordTitle = t('page.title.change_password');

  const tabItems = [
    {
      key: 1,
      text: myProfileTitle,
      content: <MyProfile />
    },
    {
      key: 2,
      text: changePasswordTitle,
      content: <ChangePassword />
    }
  ];

  return (
    <div>
      <div className="mb-5">
        <h2 className="pb-1">{page}</h2>
      </div>
      <div>
        <div className="mb-4 nav nav-underline tab-underline pb-3">
          {tabItems.map(tab => (
            <div className="nav-item" key={tab.key}>
              <a
                role="button"
                className={`px-3 nav-link ${
                  activeTab === tab.key ? 'active' : ''
                } nav-underline`}
                onClick={() => setActiveTab(tab.key)}
              >
                {tab.text}
              </a>
            </div>
          ))}
        </div>
        {tabItems.map(
          tab => activeTab === tab.key && <div key={tab.key}>{tab.content}</div>
        )}
      </div>
    </div>
  );
};

export default Profile;
