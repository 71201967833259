import { IconProp } from '@fortawesome/fontawesome-svg-core';

type SearchItemCategory =
  | 'recently_searched'
  | 'products'
  | 'quick_links'
  | 'suggestion_files'
  | 'members'
  | 'related_search';

export type SearchResult = {
  url: string;
  label: string;
  category: SearchItemCategory;
  image?: string;
  details?: string;
  format?: string;
  avatar?: string;
  status?: string;
  icon?: IconProp;
};

export const searchItems: SearchResult[] = [];
