import Avatar from 'components/base/Avatar';
import { Dropdown, Modal, Nav } from 'react-bootstrap';
import defaultUserImage from 'assets/img/team/avatar-rounded.webp';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { useAppContext } from 'providers/AppProvider';
// import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
// import ThemeToggler from 'components/common/ThemeToggler';
import React, { useEffect, useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import classNames from 'classnames';
import useAuthHook from 'hooks/useAuthHook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

const NavItems = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();

  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const [image, setImage] = useState<string>(defaultUserImage);
  const { user } = useAuthHook();

  useEffect(() => {
    const assetEndPoint = process.env.REACT_APP_ASSET_ENDPOINT;
    if (user?.image && assetEndPoint) {
      setImage(assetEndPoint + '/' + user.image);
    } else {
      setImage(defaultUserImage);
    }
  }, [user]);

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      {/* <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item> */}
      <Nav.Item
        className={classNames({
          'd-lg-none':
            navbarPosition === 'vertical' || navbarPosition === 'dual'
        })}
      >
        {/* <Nav.Link onClick={() => setOpenSearchModal(!openSearchModal)}>
          <FeatherIcon icon="search" size={19} style={{ marginBottom: 2 }} />
        </Nav.Link> */}
      </Nav.Item>

      <Nav.Item>
        <Dropdown
          autoClose="outside"
          show={showDropdown} // Control dropdown visibility
          onToggle={isOpen => setShowDropdown(isOpen)} // Handle toggle
          className="h-100"
        >
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <Avatar className="defaultUserImage" src={image} size="l" />
            <span className="ms-2">{user?.name}</span>
            {showDropdown ? (
              <FontAwesomeIcon icon={faCaretUp} className="fs-0 ms-2" />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} className="fs-0 ms-2" />
            )}
          </Dropdown.Toggle>
          <ProfileDropdownMenu
            handleToggle={() => setShowDropdown(false)}
            image={image}
          />
        </Dropdown>
      </Nav.Item>

      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NavItems;
