import React, { useEffect, useState } from 'react';
import AgreementService from '../../services/agreement/AgreementService';
import { Col, Row } from 'react-bootstrap';

const PrivacyPolicy = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    AgreementService.fetchOneAgreement('privacy-policy')
      .then(resp => {
        setContent(resp.data?.data?.content);
      })
      .catch(e => console.log(e));
  }, []);
  return (
    <div>
      <div className="px-3">
        <Row className="min-vh-100  p-2">
          <Col xs={12} xl={10} xxl={8}>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
