import { createSlice } from '@reduxjs/toolkit';
import { ActivityType } from '../../../types/actorManagement';

interface ActivityTypeState {
  activityTypes: ActivityType[];
  activityType: ActivityType;
}

const initialState: ActivityTypeState = {
  activityTypes: [],
  activityType: <ActivityType>{}
};

const activityTypeSlice = createSlice({
  name: 'activityType',
  initialState,
  reducers: {
    setAllActivityType(state, action) {
      state.activityTypes = action.payload.results || [];
    },
    addOneActivityType(state, action) {
      state.activityTypes = [...state.activityTypes, action.payload.row];
    },
    updateOneActivityType(state, action) {
      state.activityTypes = state.activityTypes.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    setOneActivityType(state, action) {
      state.activityType = action.payload.row || null;
    },
    removeOneActivityType(state, action) {
      state.activityTypes = state.activityTypes.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  setAllActivityType,
  addOneActivityType,
  setOneActivityType,
  removeOneActivityType,
  updateOneActivityType
} = activityTypeSlice.actions;
export default activityTypeSlice.reducer;
