import { RouteObject } from 'react-router-dom';
import SignIn from '../pages/authentication/card/SignIn';
import SignUp from '../pages/authentication/card/SignUp';
import SignOut from '../pages/authentication/card/SignOut';
import ForgotPassword from '../pages/authentication/card/ForgotPassword';
import ResetPassword from '../pages/authentication/card/ResetPassword';
import GuestGuard from '../utils/route-guard/GuestGuard';

const AuthRoutes: RouteObject = {
  path: '/auth/',

  children: [
    {
      path: 'sign-in',
      element: (
        <GuestGuard>
          <SignIn />
        </GuestGuard>
      )
    },
    {
      path: 'sign-up',
      element: (
        <GuestGuard>
          <SignUp />
        </GuestGuard>
      )
    },
    {
      path: 'sign-out',
      element: (
        <GuestGuard>
          <SignOut />
        </GuestGuard>
      )
    },
    {
      path: 'forgot-password',
      element: (
        <GuestGuard>
          <ForgotPassword />
        </GuestGuard>
      )
    },
    {
      path: 'reset-password',
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      )
    }
  ]
};

export default AuthRoutes;
