import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import AuthService from '../services/auth/AuthService';
import { getAuthFromLocalStorage, removeAuthFromLocalStorage } from './storage';
import { dispatch } from '../store';
import { setToken } from '../store/reducers/auth/authSlice';
import { commonAlert } from '../components/common/CommonAlert';
import { confirmExpiredTokenAlert } from '../components/common/ConfirmExpiredTokenAlert';

const auth = getAuthFromLocalStorage();

const axiosServices = axios.create({
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + auth.dhToken //the token is a variable which holds the token
  }
});

const isTokenExpired = () => {
  try {
    const auth = getAuthFromLocalStorage();
    if (auth?.dhToken) {
      const decoded = jwtDecode(auth.dhToken);
      const now = Math.floor(new Date().getTime() / 1000);
      const nowAfterFive = now + 300; //5 min bufferTime
      const exp = decoded?.exp || 0;
      // console.log(exp);
      return nowAfterFive >= exp;
    } else {
      return false;
    }
  } catch (e) {
    return true;
  }
};
let isAlertShown = false; // Flag to prevent multiple alerts
axiosServices.interceptors.response.use(
  async response => {
    if (isTokenExpired()) {
      try {
        const resp = await AuthService.refreshToken();
        const token: string = resp?.data?.access_token || '';
        const refreshToken: string = resp?.data?.refresh_token || '';
        if (token && refreshToken) {
          response.headers['Authorization'] = `Bearer ${token}`; // Update current request's Authorization header
          axiosServices.defaults.headers['Authorization'] = `Bearer ${token}`; // Update axios default Authorization header for future requests
          dispatch(setToken({ token }));
          localStorage.setItem('dhToken', token);
          localStorage.setItem('dhRefreshToken', refreshToken);
        }
      } catch (e) {
        console.log(e);
      }
    }
    return response;
  },
  async error => {
    if (error.response?.status === 401 && !isAlertShown) {
      isAlertShown = true;
      confirmExpiredTokenAlert().then(resp => {
        if (resp) {
          redirectToLoginPage();
        }
      });
    } else if (error.response?.status === 409) {
      commonAlert({
        title: 'Error',
        textColor: 'danger',
        message: error.response?.data?.message || 'Something went wrong'
      });
      return Promise.reject(error.response || 'Something went wrong');
    } else {
      return Promise.reject(error.response || 'Something went wrong');
    }
  }
);
const redirectToLoginPage = () => {
  removeAuthFromLocalStorage();
  setTimeout(() => {
    window.location.href =
      process.env.REACT_APP_MAIN_DOMAIN || '' + '/auth/sign-in';
  }, 500);
};

export default axiosServices;
