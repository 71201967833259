import { createSlice } from '@reduxjs/toolkit';
import { TreatmentTypesType } from 'types/encounterManagement';

interface TreatmentTypeState {
  treatmentTypes: TreatmentTypesType[];
}

const initialState: TreatmentTypeState = {
  treatmentTypes: []
};

const treatmentTypeSlice = createSlice({
  name: 'treatmentType',
  initialState,
  reducers: {
    setAllTreatmentType(state, action) {
      state.treatmentTypes = action.payload.results || [];
    }
  }
});

export const { setAllTreatmentType } = treatmentTypeSlice.actions;
export default treatmentTypeSlice.reducer;
