import axios from 'axios';

const endPoint: string = `${process.env.REACT_APP_ENDPOINT}/mdm/pages/view`;

const fetchOneAgreement = (type: string) => {
  return axios.get(`${endPoint}/${type}`);
};

const AgreementService = {
  fetchOneAgreement
};
export default AgreementService;
