import { createSlice } from '@reduxjs/toolkit';
import { TenantProfileType, UserProfileType } from 'types/settings';

interface GeneralSettingsState {
  tenantProfile: TenantProfileType;
  userProfile: UserProfileType;
}

const initialState: GeneralSettingsState = {
  tenantProfile: {} as TenantProfileType,
  userProfile: {} as UserProfileType
};

const generalSettingsSlice = createSlice({
  name: 'generalSettings',
  initialState,
  reducers: {
    setTenantProfile(state, action) {
      state.tenantProfile = action.payload.row;
    },
    setUserProfile(state, action) {
      state.userProfile = action.payload.row;
    }
  }
});

export const { setTenantProfile, setUserProfile } =
  generalSettingsSlice.actions;
export default generalSettingsSlice.reducer;
