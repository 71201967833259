import Cookies from 'universal-cookie';

const cookies = new Cookies();

// eslint-disable-next-line
export const addAuthToCookie = (payload: any) => {
  cookies.set('dhToken', payload.token, {
    path: '/'
  });
  cookies.set('dhUser', payload.user, {
    path: '/'
  });
  cookies.set('dhTenant', payload.tenant, {
    path: '/'
  });
  cookies.set('dhRefreshToken', payload.refreshToken, {
    path: '/'
  });
  cookies.set('dhClientId', payload.clientId, {
    path: '/'
  });
  cookies.set('dhClientSecret', payload.clientSecret, {
    path: '/'
  });
  cookies.set('dhScopes', payload.scopes, {
    path: '/'
  });
};
export const removeAuthFromCookie = () => {
  cookies.remove('dhTenant', {
    path: '/'
  });
  cookies.remove('dhUser', {
    path: '/'
  });
  cookies.remove('dhToken', {
    path: '/'
  });
  cookies.remove('dhRefreshToken', {
    path: '/'
  });
  cookies.remove('dhClientSecret', {
    path: '/'
  });
  cookies.remove('dhClientId', {
    path: '/'
  });

  cookies.remove('dhScopes', {
    path: '/'
  });
};

// eslint-disable-next-line
export const addAuthToLocalStorage = (payload: any) => {
  localStorage.setItem('dhToken', payload.token);

  localStorage.setItem('dhRefreshToken', payload.refreshToken);
  localStorage.setItem('dhClientId', payload.clientId);
  localStorage.setItem('dhClientSecret', payload.clientSecret);
  localStorage.setItem('dhScopes', JSON.stringify(payload.scopes));
  localStorage.setItem('dhResources', JSON.stringify(payload.resources));
  localStorage.setItem('dhUser', JSON.stringify(payload.user));
  localStorage.setItem('dhTenant', JSON.stringify(payload.tenant));
};

// eslint-disable-next-line
export const updateUserInLocalStorage = (payload: any, key: string) => {
  localStorage.setItem(key, JSON.stringify(payload));
};

export const removeAuthFromLocalStorage = () => {
  localStorage.removeItem('dhToken');
  localStorage.removeItem('dhRefreshToken');
  localStorage.removeItem('dhClientId');
  localStorage.removeItem('dhClientSecret');
  localStorage.removeItem('dhScopes');
  localStorage.removeItem('dhResources');
  localStorage.removeItem('dhUser');
  localStorage.removeItem('dhTenant');
};

export const getAuthFromLocalStorage = () => {
  const scopes = localStorage.getItem('dhScopes') || null;
  const resources = localStorage.getItem('dhResources') || null;
  const tenant = localStorage.getItem('dhTenant') || null;
  const user = localStorage.getItem('dhUser') || null;
  let dhTenant = null;
  let dhUser = null;
  let dhScopes = [];
  let dhResources = [];
  try {
    dhTenant = tenant ? JSON.parse(tenant) : null;
    dhUser = user ? JSON.parse(user) : null;
    dhScopes = scopes ? JSON.parse(scopes) : [];
    dhResources = resources ? JSON.parse(resources) : [];
  } catch (e) {
    console.log(e);
    removeAuthFromLocalStorage();
  }
  return {
    dhToken: localStorage.getItem('dhToken') || '',
    dhRefreshToken: localStorage.getItem('dhRefreshToken') || '',
    dhClientId: localStorage.getItem('dhClientId') || '',
    dhClientSecret: localStorage.getItem('dhClientSecret') || '',
    dhTenant,
    dhUser,
    dhScopes,
    dhResources
  };
};
