import React from 'react';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { AlertType } from '../../types/alert';

const AlertMessage = ({ type, message, timer }: AlertType) => {
  const { t }: { t: TFunction } = useTranslation();

  if (type == 'SUCCESS') {
    return (
      <Alert variant="subtle-success">
        <span className="text-success font-medium">
          <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-success" />
          {message}
          {timer !== null && timer !== undefined && (
            <> {t('auth.resetPassword.message.redirect', { timer })}</>
          )}
        </span>
      </Alert>
    );
  }

  if (type == 'ERROR') {
    return (
      <Alert variant="subtle-danger">
        <span className="text-danger  font-medium">
          <FontAwesomeIcon
            className="me-2 text-danger"
            icon={faTriangleExclamation}
          />
          {message}
        </span>
      </Alert>
    );
  }
  if (type == 'WARNING') {
    return (
      <Alert variant="subtle-warning">
        <span className="text-warning  font-medium">
          <FontAwesomeIcon
            className="me-2 text-danger"
            icon={faTriangleExclamation}
          />
          {message}
        </span>
      </Alert>
    );
  }
};

export default AlertMessage;
