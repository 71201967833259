import React from 'react';
import { Modal } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';

interface AlertProps {
  title: string;
  message: string | JSX.Element;
  textColor?: string;
  onCancel: () => void;
}

const CommonAlert: React.FC<AlertProps> = ({
  message,
  title,
  textColor,
  onCancel
}) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  return (
    <Modal
      show={true}
      backdrop="static"
      onHide={onCancel}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className={`text-${textColor}`}
          style={{ fontSize: '1rem' }}
        >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`text-${textColor}`}>
        <p>{message}</p>
      </Modal.Body>
    </Modal>
  );
};
export const commonAlert = ({
  title,
  message,
  textColor
}: {
  title: string;
  message: string | JSX.Element;
  textColor?: string;
}) => {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = createRoot(div);
  const handleCancel = () => {
    cleanup();
  };

  const cleanup = () => {
    root.unmount();
    document.body.removeChild(div);
  };
  root.render(
    <CommonAlert
      title={title}
      message={message}
      textColor={textColor}
      onCancel={handleCancel}
    />
  );
};
