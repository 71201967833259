import { createSlice } from '@reduxjs/toolkit';
import { FormFieldType } from 'types/settings';

interface FieldCustomizationState {
  patientFormFields: FormFieldType[];
  schoolFormFields: FormFieldType[];
  venueTypeFormFields: FormFieldType[];
  screeningFormFields: FormFieldType[];
  referralFormFields: FormFieldType[];
}

const initialState: FieldCustomizationState = {
  schoolFormFields: [],
  venueTypeFormFields: [],
  patientFormFields: [],
  screeningFormFields: [],
  referralFormFields: []
};

const fieldCustomizationSlice = createSlice({
  name: 'fieldCustomization',
  initialState,
  reducers: {
    setAllSchoolFormFields(state, action) {
      state.schoolFormFields = action.payload.results || [];
    },
    setAllVenueTypeFormFields(state, action) {
      state.venueTypeFormFields = action.payload.results || [];
    },
    setAllPatientFormFields(state, action) {
      state.patientFormFields = action.payload.results || [];
    },
    setAllScreeningFormFields(state, action) {
      state.screeningFormFields = action.payload.results || [];
    },
    setAllReferralFormFields(state, action) {
      state.referralFormFields = action.payload.results || [];
    }
  }
});

export const {
  setAllPatientFormFields,
  setAllSchoolFormFields,
  setAllVenueTypeFormFields,
  setAllScreeningFormFields,
  setAllReferralFormFields
} = fieldCustomizationSlice.actions;
export default fieldCustomizationSlice.reducer;
