import { RouteObject } from 'react-router-dom';
import MainLayoutProvider from '../providers/MainLayoutProvider';
import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../layouts/MainLayout';
import { lazy, Suspense } from 'react';
import PhoenixLoader from '../components/common/PhoenixLoader';

const SettingsPage = lazy(() => import('pages/settings/SettingsPage/'));

const SettingsRoutes: RouteObject = {
  path: '/settings/',
  element: (
    <MainLayoutProvider>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </MainLayoutProvider>
  ),
  children: [
    {
      path: 'settings',
      index: true,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <SettingsPage />
        </Suspense>
      )
    }
  ]
};

export default SettingsRoutes;
