import { createSlice } from '@reduxjs/toolkit';

import { EncounterTabType } from 'types/settings';

interface EncounterSettingsState {
  tabs: EncounterTabType[];
}

const initialState: EncounterSettingsState = {
  tabs: []
};

const encounterSettingsSlice = createSlice({
  name: 'encounterSettings',
  initialState,
  reducers: {
    setAllEncounterSettingsTabs(state, action) {
      state.tabs = [...(action.payload || [])];
    }
  }
});

export const { setAllEncounterSettingsTabs } = encounterSettingsSlice.actions;
export default encounterSettingsSlice.reducer;
