import { createSlice } from '@reduxjs/toolkit';
import { TenantSettingType } from 'types/settings';

interface TenantSettingsState {
  settings: TenantSettingType[];
}

const initialState: TenantSettingsState = {
  settings: []
};

const tenantSettingsSlice = createSlice({
  name: 'tenantSettings',
  initialState,
  reducers: {
    setAllTenantSetting(state, action) {
      state.settings = action.payload.result;
    },
    setTenantSetting(state, action) {
      const key = action.payload?.row?.key || null;
      const value = action.payload?.row?.value || '';
      if (key) {
        const filters = state.settings.filter(item => item.key == key);
        if (filters.length > 0) {
          state.settings = state.settings.map(item => {
            if (item.key == key) {
              item.value = value;
            }
            return item;
          });
        } else {
          state.settings = [...state.settings, action.payload.row];
        }
      }
    }
  }
});

export const { setAllTenantSetting, setTenantSetting } =
  tenantSettingsSlice.actions;
export default tenantSettingsSlice.reducer;
