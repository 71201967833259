import React, { useEffect, useMemo } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { RouteItems, routes } from 'sitemap';
import { capitalize } from 'helpers/utils';
import {
  UilArrowFromRight,
  UilLeftArrowToLeft
} from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';

import { useAppContext } from 'providers/AppProvider';
import Button from 'components/base/Button';
import { faFolder } from '@fortawesome/free-solid-svg-icons';

import { useBreakpoints } from 'providers/BreakpointsProvider';

import NavbarVerticalMenu from './NavbarVerticalMenu';
import NavbarVerticalCollapseProvider from './NavbarVerticalCollapseProvider';
import NavbarTopNav from '../navbar-horizontal/NavbarTopNav';

import useActivityTypeHook from '../../../hooks/actor-management/useActivityTypeHook';
import useAuthHook from '../../../hooks/useAuthHook';
import { checkScope } from '../../../helpers/auth';

const NavbarVertical = () => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarVerticalAppearance,
      isNavbarVerticalCollapsed
    },
    setConfig
  } = useAppContext();

  const { breakpoints } = useBreakpoints();
  const { activityTypes, fetchAllActivityType } = useActivityTypeHook();
  const { isAuthenticated } = useAuthHook();

  useEffect(() => {
    if (isAuthenticated) {
      fetchAllActivityType().catch(e => console.log(e.message));
    }
  }, [isAuthenticated]);

  const routesMemo = useMemo(() => {
    let mappedRoutes: RouteItems[] = JSON.parse(JSON.stringify(routes));

    mappedRoutes = mappedRoutes
      .filter(routeParent =>
        routeParent.permission ? checkScope(routeParent.permission) : true
      )
      .map(route => {
        route.pages = route.pages
          .filter(data =>
            data.permission ? checkScope(data.permission) : true
          )
          .map(route2 => {
            if (route2.pages) {
              route2.pages = route2.pages?.filter(data =>
                data.permission ? checkScope(data.permission) : true
              );
            } else {
              route2.pages = undefined;
            }

            return route2;
          });
        return route;
      });
    console.log(mappedRoutes);

    mappedRoutes = mappedRoutes.map(route => {
      // route.label = t(`common.label.${route.label}`);

      // Add dynamic activity types under the dashboard
      if (route.label.toLowerCase() === 'dashboard') {
        const existingPageNames = new Set(
          route.pages.map(page => page.pathName)
        );
        if (checkScope('actor-activitytype-list')) {
          activityTypes.forEach(activity => {
            if (!existingPageNames.has(activity.name)) {
              route.pages.push({
                name: activity.name,
                is_dynamic: true,
                path: `/activity-type/${activity.id}/${activity.venue_type_id}`,
                pathName: `activity_type${activity.name}`,
                active: true,
                icon: activity.icon || faFolder
              });
            }
          });
        }
      }

      return route;
    });

    return mappedRoutes;
  }, [activityTypes]);

  return (
    <NavbarVerticalCollapseProvider>
      <Navbar
        className="navbar-vertical"
        expand="lg"
        variant=""
        data-navbar-appearance={
          navbarVerticalAppearance === 'darker' ? 'darker' : ''
        }
      >
        <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical}>
          <div className="navbar-vertical-content">
            <Nav className="flex-column" as="ul" id="navbarVerticalNav">
              {routesMemo.map((route, index) => (
                <React.Fragment key={route.label}>
                  {index !== 0 && <hr className="custom-hr ms-3 me-3" />}
                  <Nav.Item key={route.label}>
                    {!route.labelDisabled && (
                      <>
                        <p className="navbar-vertical-label">
                          {capitalize(route.label)}
                        </p>
                        <hr className="navbar-vertical-line" />
                      </>
                    )}

                    <NavbarVerticalMenu level={1} routes={route.pages} />
                  </Nav.Item>
                </React.Fragment>
              ))}
            </Nav>

            {navbarPosition === 'combo' && breakpoints.down('lg') && (
              <div className="move-container">
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr" />
                </div>
                <NavbarTopNav />
              </div>
            )}
          </div>
        </Navbar.Collapse>

        <div className="navbar-vertical-footer">
          <Button
            className="navbar-vertical-toggle border-0 fw-semibold w-100 white-space-nowrap d-flex align-items-center"
            onClick={() => {
              setConfig({
                isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed
              });
            }}
          >
            {isNavbarVerticalCollapsed ? (
              <UilArrowFromRight size={16} className="mb-1" />
            ) : (
              <>
                <UilLeftArrowToLeft size={16} className="mb-1" />
                <span className="ms-2">
                  {t(`common.action.collapsed_view`)}
                </span>
              </>
            )}
          </Button>
        </div>
      </Navbar>
    </NavbarVerticalCollapseProvider>
  );
};

export default NavbarVertical;
