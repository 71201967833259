import { RouteObject } from 'react-router-dom';
import GuestGuard from 'utils/route-guard/GuestGuard';
import TermsAndConditions from 'pages/terms-and-conditions';
import PrivacyPolicy from 'pages/privacy-policy';

const AgreementRoutes: RouteObject = {
  path: '/',

  children: [
    {
      path: 'terms-and-conditions',
      element: (
        <GuestGuard>
          <TermsAndConditions />
        </GuestGuard>
      )
    },
    {
      path: 'privacy-policy',
      element: (
        <GuestGuard>
          <PrivacyPolicy />
        </GuestGuard>
      )
    }
  ]
};

export default AgreementRoutes;
