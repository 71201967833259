import { createSlice } from '@reduxjs/toolkit';
import { ActorCategoryType } from '../../../types/actorManagement';

interface ActorCategoryState {
  actorCategories: ActorCategoryType[];
}

const initialState: ActorCategoryState = {
  actorCategories: []
};

const actorCategorySlice = createSlice({
  name: 'actorCategory',
  initialState,
  reducers: {
    setAllActorCategory(state, action) {
      state.actorCategories = action.payload.results || [];
    },
    addOneActorCategory(state, action) {
      state.actorCategories = [...state.actorCategories, action.payload.row];
    },
    setOneActorCategory(state, action) {
      state.actorCategories = state.actorCategories.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneActorCategory(state, action) {
      state.actorCategories = state.actorCategories.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  setAllActorCategory,
  addOneActorCategory,
  setOneActorCategory,
  removeOneActorCategory
} = actorCategorySlice.actions;
export default actorCategorySlice.reducer;
