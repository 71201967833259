import { createBrowserRouter, RouteObject } from 'react-router-dom';
import Error404 from 'pages/error/Error404';
import App from 'App';
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import ActorManagementRoutes from './ActorManagementRoutes';
import UserManagementRoutes from './UserManagementRoutes';
import Error500 from '../pages/error/Error500';
import PatientManagementRoutes from './PatientManagementRoutes';
import SettingsRoutes from './SettingsRoutes';
import ProfileRoutes from './ProfileRoutes';
import MdmRoutes from './MdmRoutes';
import EncounterManagementRoutes from './EncounterManagementRoutes';
import AgreementRoutes from './AgreementRoutes';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      MainRoutes,
      MdmRoutes,
      ActorManagementRoutes,
      PatientManagementRoutes,
      EncounterManagementRoutes,
      UserManagementRoutes,
      SettingsRoutes,
      ProfileRoutes,
      AuthRoutes,
      AgreementRoutes,
      {
        path: '*',
        element: <Error404 />
      },
      {
        path: '/server-error',
        element: <Error500 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
