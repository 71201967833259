import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// types
import useAuthHook from '../../hooks/useAuthHook';

// ==============================|| AUTH GUARD ||============================== //
export type GuardProps = {
  children: ReactElement | null;
};

const AuthGuard = ({ children }: GuardProps) => {
  const { isAuthenticated } = useAuthHook();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(location?.state?.from ? location?.state?.from : '/', {
        state: {
          from: location.pathname
        },
        replace: true
      });
    } else {
      const appEnv = process.env.REACT_APP_APP_ENV || 'local';
      if (appEnv.toLowerCase() != 'local') {
        const origin = window.location.origin;
        const mainDomain = process.env.REACT_APP_MAIN_DOMAIN || null;
        if (mainDomain && origin != mainDomain) {
          window.location.href = mainDomain + '/auth/sign-in';
        }
      }
    }
  }, [isAuthenticated, navigate, location]);

  return children;
};

export default AuthGuard;
