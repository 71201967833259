import { createSlice } from '@reduxjs/toolkit';
import { PatientType } from '../../../types/patientManagement';
import { MetaType } from 'types';

interface PatientState {
  meta: MetaType;
  patients: PatientType[];
}

const initialState: PatientState = {
  meta: <MetaType>{},
  patients: []
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setAllPatient(state, action) {
      state.patients = action.payload.results || [];
      state.meta = action.payload.meta || <MetaType>{};
    },
    addOnePatient(state, action) {
      state.patients = [...state.patients, action.payload.row];
    },
    setOnePatient(state, action) {
      state.patients = state.patients.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOnePatient(state, action) {
      state.patients = state.patients.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const { setAllPatient, addOnePatient, setOnePatient, removeOnePatient } =
  patientSlice.actions;
export default patientSlice.reducer;
