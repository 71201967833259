import { createSlice } from '@reduxjs/toolkit';
import { FormOptionType } from 'types/settings';

interface FormOptionState {
  formOptions: FormOptionType[];
}

const initialState: FormOptionState = {
  formOptions: []
};

const formOptionSlice = createSlice({
  name: 'formOption',
  initialState,
  reducers: {
    setAllFormOption(state, action) {
      state.formOptions = action.payload.results || [];
    },
    addOneFormOption(state, action) {
      state.formOptions = [...state.formOptions, action.payload.row];
    },
    setOneFormOption(state, action) {
      state.formOptions = state.formOptions.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneFormOption(state, action) {
      state.formOptions = state.formOptions.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  setAllFormOption,
  addOneFormOption,
  setOneFormOption,
  removeOneFormOption
} = formOptionSlice.actions;
export default formOptionSlice.reducer;
