import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  return (
    <footer className={classNames(className, 'footer')}>
      <Row className="g-1 justify-content-between align-items-center h-100">
        <Col xs={12} sm="auto" className="text-center">
          <p className="mb-0 mt-2 mt-sm-0 fs-9">
            {t(`common.label.copy_rights`)}
          </p>
        </Col>
        <Col xs={12} sm="auto" className="text-center">
          <p className="mb-0 fs-9 text-body-tertiary text-opacity-85">
            {t(`common.label.develop_by`)}{' '}
            <a target="_blank" href="https://pegotec.net/" rel="noreferrer">
              Pegotec
            </a>
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
