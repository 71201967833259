import { faChevronRight, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useAuthHook from '../../../hooks/useAuthHook';
import { ForgotPassword } from '../../../types/auth';
import AlertMessage from '../../common/AlertMessage';
import { Link } from 'react-router-dom';
import { AlertType } from '../../../types/alert';

const ForgotPasswordForm = ({
  layout
}: {
  layout?: 'simple' | 'card' | 'split';
}) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { forgotPassword } = useAuthHook();
  const [alert, setAlert] = useState<AlertType | null>(null);
  const initialValues: { to: string } = {
    to: ''
  };
  const [loader, setLoader] = useState<{ form?: boolean }>({
    form: false
  });
  const validationSchema = Yup.object({
    to: Yup.string()
      .required(
        t('component.form.validation.mandatory', {
          field: t('common.label.email').toLowerCase()
        })
      )
      .email(t('component.form.validation.invalid_email'))
  });
  const onSubmit = async (
    values: { to: string },
    { resetForm }: FormikHelpers<{ to: string }>
  ) => {
    const data: ForgotPassword = {
      to: values.to,
      reset_url: process.env.REACT_APP_PASSWORD_LINK || ''
    };
    setLoader({ form: true });
    forgotPassword(data)
      .then(() => {
        setLoader({ form: false });
        resetForm();
        setAlert({
          type: 'SUCCESS',
          message: t('auth.forgotPassword.message.success')
        });
      })
      .catch(e => {
        if (e?.response?.status == '404') {
          setAlert({
            type: 'ERROR',
            message: t('common.message.user.not_found')
          });
        } else {
          setAlert({ type: 'ERROR', message: t('common.message.failed') });
        }
        setLoader({ form: false });
      });
  };

  return (
    <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
      <div
        className={classNames('text-center', { 'mb-6': !(layout === 'split') })}
      >
        <h4 className="text-body-highlight">
          {' '}
          <Trans t={t}>auth.forgotPassword.title</Trans>?
        </h4>
        <p className="text-body-tertiary mb-5">
          <Trans t={t}>auth.forgotPassword.sub_title</Trans>
        </p>
        {alert && <AlertMessage type={alert.type} message={alert.message} />}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="d-flex align-items-center flex-column gap-3 mb-2"
            >
              <div className="form-icon-container w-100 text-start">
                <Form.Control
                  id="email"
                  type="email"
                  name="to"
                  className={`form-control form-icon-input ${
                    touched.to && errors.to ? 'is-invalid' : ''
                  }`}
                  placeholder={t('common.label.email')}
                  value={values.to}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                {touched.to && errors.to && (
                  <Form.Control.Feedback type="invalid">
                    {errors.to}
                  </Form.Control.Feedback>
                )}

                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="text-body fs-9 form-icon"
                />
              </div>
              <Button
                type="submit"
                variant="primary"
                className="ms-2"
                endIcon={
                  <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                }
                disabled={isSubmitting || loader.form}
              >
                <Trans t={t}>common.action.reset_password</Trans>
              </Button>
            </Form>
          )}
        </Formik>
        <Link to="/auth/sign-in" className="fs-9 mt-2 fw-bold">
          <Trans t={t}>common.action.back_to_login</Trans>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
