import Button from 'components/base/Button';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import useAuthHook from '../../../hooks/useAuthHook';
import { ResetPassword } from '../../../types/auth';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AlertMessage from '../../common/AlertMessage';
import { AlertType } from '../../../types/alert';

interface ResetFormPassword {
  password: string;
  confirm_password: string;
}

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { t }: { t: TFunction } = useTranslation();
  const [queryParameters] = useSearchParams();
  const { resetPassword } = useAuthHook();
  const [alert, setAlert] = useState<AlertType | null>(null);
  const initialValues: ResetFormPassword = {
    password: '',
    confirm_password: ''
  };
  const [loader, setLoader] = useState<{ form?: boolean }>({
    form: false
  });
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (countdown !== null) {
      countdownInterval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown === 1) {
            clearInterval(countdownInterval);
            navigate('/auth/sign-in');
            return null;
          }
          return prevCountdown ? prevCountdown - 1 : null;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [countdown, navigate]);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(
        t('component.form.validation.mandatory', {
          field: t('common.label.password').toLowerCase()
        })
      )
      .min(
        6,
        t('component.form.validation.min', {
          field: t('common.label.password').toLowerCase(),
          min: '6'
        })
      ),
    confirm_password: Yup.string()
      .required(
        t('component.form.validation.mandatory', {
          field: t('common.label.confirm_password').toLowerCase()
        })
      )
      .min(
        6,
        t('component.form.validation.min', {
          field: t('common.label.confirm_password').toLowerCase(),
          min: '6'
        })
      )
      .oneOf(
        [Yup.ref('password')],
        t('component.form.validation.match.password')
      )
  });

  const onSubmit = async (values: ResetFormPassword) => {
    const email = queryParameters.get('email') || '';
    const token = queryParameters.get('token') || '';
    if (!email && !token) {
      setAlert({
        type: 'ERROR',
        message: t('common.message.email_or_token_missing')
      });
      return;
    }
    const data: ResetPassword = {
      email,
      token,
      new_password: values.password
    };
    setLoader({ form: true });
    setSubmitDisabled(true);

    resetPassword(data)
      .then(() => {
        setLoader({ form: false });
        setAlert({
          type: 'SUCCESS',
          message: t('auth.resetPassword.message.success', { time: 5 })
        });
        setSubmitDisabled(false);
        setCountdown(5);
      })
      .catch(e => {
        setLoader({ form: false });
        if (e?.response?.status === 404) {
          setAlert({ type: 'ERROR', message: 'User not found!' });
        } else {
          setAlert({ type: 'ERROR', message: 'Something went wrong!' });
        }
      });
  };

  return (
    <div className="text-center mb-6">
      <h4 className="text-body-highlight">
        <Trans t={t}>auth.resetPassword.title</Trans>
      </h4>
      <p className="text-body-tertiary">
        <Trans t={t}>auth.resetPassword.sub_title</Trans>
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit
        }) => (
          <Form
            className="mt-5 mb-2 text-start"
            noValidate
            onSubmit={handleSubmit}
          >
            {alert && (
              <AlertMessage
                type={alert.type}
                message={alert.message}
                timer={countdown}
              />
            )}
            <Form.Group className="mb-3 ">
              <Form.Label htmlFor="confirm_password">
                <Trans t={t}>common.label.password</Trans>
              </Form.Label>
              <div className="form-icon-container mb-2">
                <Form.Control
                  id="password"
                  type="password"
                  name="password"
                  className={`form-control form-icon-input ${
                    touched.password && errors.password ? 'is-invalid' : ''
                  }`}
                  placeholder={t('common.label.password')}
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="true"
                />
                {touched.password && errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
                <FontAwesomeIcon
                  icon={faKey}
                  className="text-body fs-9 form-icon"
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 ">
              <Form.Label htmlFor="confirm_password">
                <Trans t={t}>common.label.confirm_password</Trans>
              </Form.Label>
              <div className="form-icon-container mb-4">
                <Form.Control
                  id="confirm_password"
                  type="password"
                  name="confirm_password"
                  className={`form-control form-icon-input ${
                    touched.confirm_password && errors.confirm_password
                      ? 'is-invalid'
                      : ''
                  }`}
                  placeholder={t('common.label.confirm_password')}
                  value={values.confirm_password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="true"
                />
                {touched.confirm_password && errors.confirm_password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.confirm_password}
                  </Form.Control.Feedback>
                )}
                <FontAwesomeIcon
                  icon={faKey}
                  className="text-body fs-9 form-icon"
                />
              </div>
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              className="w-100"
              disabled={isSubmitting || loader.form || isSubmitDisabled}
            >
              <Trans t={t}>common.action.set_password</Trans>
            </Button>
          </Form>
        )}
      </Formik>
      <Link to="/auth/sign-in" className="fs-9 fw-bold">
        <Trans t={t}>common.action.back_to_login</Trans>
      </Link>
    </div>
  );
};

export default ResetPasswordForm;
