import { createSlice } from '@reduxjs/toolkit';
import {
  EncounterDiagnosticType,
  EncounterHistoryType,
  EncounterPeriodontalType,
  EncounterReasonType,
  EncounterReferralType,
  EncounterScreeningType,
  EncounterTreatmentType,
  EncounterType
} from '../../../types/encounterManagement';
import { TabSetting } from '../../../types';

interface EncounterState {
  encounters: EncounterType[];
  encounterTabFormSettings: TabSetting[];
  encounter: EncounterType | null;
  history: EncounterHistoryType | null;
  screening: EncounterScreeningType | null;
  periodontal: EncounterPeriodontalType | null;
  diagnostic: EncounterDiagnosticType | null;
  treatment: EncounterTreatmentType | null;
  referral: EncounterReferralType | null;
  reasons: EncounterReasonType[];
}

const initialState: EncounterState = {
  encounters: [],
  encounterTabFormSettings: [],
  encounter: null,
  history: null,
  screening: null,
  diagnostic: null,
  periodontal: null,
  treatment: null,
  referral: null,
  reasons: []
};

const encounterSlice = createSlice({
  name: 'encounter',
  initialState,
  reducers: {
    setAllEncounter(state, action) {
      state.encounters = action.payload.results || [];
    },
    addOneEncounter(state, action) {
      state.encounters = [...state.encounters, action.payload.row];
    },
    updateOneEncounter(state, action) {
      state.encounters = state.encounters.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    setOneEncounter(state, action) {
      state.encounter = action.payload.row;
    },
    removeOneEncounter(state, action) {
      state.encounters = state.encounters.filter(
        item => item.id !== action.payload.id
      );
    },
    setOneEncounterHistory(state, action) {
      state.history = action.payload.row;
    },
    setOneEncounterScreening(state, action) {
      state.screening = action.payload.row;
    },
    setOneEncounterPeriodontal(state, action) {
      state.periodontal = action.payload.row;
    },
    setOneEncounterDiagnostic(state, action) {
      state.diagnostic = action.payload.row;
    },
    setOneEncounterTreatment(state, action) {
      state.diagnostic = action.payload.row;
    },
    setOneEncounterReferral(state, action) {
      state.diagnostic = action.payload.row;
    },
    setAllEncounterReason(state, action) {
      state.reasons = action.payload.results || [];
    },
    addOneEncounterReason(state, action) {
      state.reasons = [...state.reasons, action.payload.row];
    },
    updateOneEncounterReason(state, action) {
      state.reasons = state.reasons.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneEncounterReason(state, action) {
      state.reasons = state.reasons.filter(
        item => item.id !== action.payload.id
      );
    },
    setAllEncounterTabSetting(state, action) {
      state.encounterTabFormSettings = action.payload.results || [];
    }
  }
});

export const {
  setAllEncounter,
  addOneEncounter,
  updateOneEncounter,
  setOneEncounter,
  removeOneEncounter,
  setOneEncounterHistory,
  setOneEncounterScreening,
  setOneEncounterPeriodontal,
  setOneEncounterDiagnostic,
  setOneEncounterTreatment,
  setOneEncounterReferral,
  setAllEncounterReason,
  addOneEncounterReason,
  updateOneEncounterReason,
  removeOneEncounterReason,
  setAllEncounterTabSetting
} = encounterSlice.actions;
export default encounterSlice.reducer;
