import { createSlice } from '@reduxjs/toolkit';
import { GeoLocationTreeType, GeoLocationType } from '../../../types/mdm';

interface LocationState {
  locations: GeoLocationType[];
  treeLocations: GeoLocationTreeType[];
}

const initialState: LocationState = {
  treeLocations: [],
  locations: []
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setAllLocationTree(state, action) {
      state.treeLocations = action.payload.results || [];
    },
    setAllLocation(state, action) {
      state.locations = action.payload.results || [];
    },
    addOneLocation(state, action) {
      state.locations = [...state.locations, action.payload.row];
    },
    setOneLocation(state, action) {
      state.locations = state.locations.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneLocation(state, action) {
      state.locations = state.locations.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  setAllLocationTree,
  setAllLocation,
  addOneLocation,
  setOneLocation,
  removeOneLocation
} = locationSlice.actions;
export default locationSlice.reducer;
