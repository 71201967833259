import { createSlice } from '@reduxjs/toolkit';
import { MetaType } from 'types';
import { BookingType } from 'types/bookingManagement';

interface BookingState {
  triageCandidates: BookingType[];
  triageCandidatesMeta: MetaType;
  bookings: BookingType[];
  bookingMeta: MetaType;
}

const initialState: BookingState = {
  triageCandidates: [],
  triageCandidatesMeta: <MetaType>{},
  bookings: [],
  bookingMeta: <MetaType>{}
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setAllTriageCandidates(state, action) {
      state.triageCandidates = action.payload.results || [];
      state.triageCandidatesMeta = action.payload.meta || <MetaType>{};
    },
    setAllBooking(state, action) {
      state.bookings = action.payload.results || [];
      state.bookingMeta = action.payload.meta || <MetaType>{};
    },
    updateOneBooking(state, action) {
      state.bookings = state.bookings.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    }
  }
});

export const { setAllTriageCandidates, setAllBooking, updateOneBooking } =
  bookingSlice.actions;
export default bookingSlice.reducer;
