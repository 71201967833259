import { createSlice } from '@reduxjs/toolkit';
import { ActorType } from '../../../types/actorManagement';

interface ActorState {
  actors: ActorType[];
  actor: ActorType;
}

const initialState: ActorState = {
  actors: [],
  actor: <ActorType>{}
};

const actorSlice = createSlice({
  name: 'actor',
  initialState,
  reducers: {
    setAllActor(state, action) {
      state.actors = action.payload.results || [];
    },
    addOneActor(state, action) {
      state.actors = [...state.actors, action.payload.row];
    },
    updateOneActor(state, action) {
      state.actors = state.actors.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    setOneActor(state, action) {
      state.actor = action.payload.row;
    },
    removeOneActor(state, action) {
      state.actors = state.actors.filter(item => item.id !== action.payload.id);
    }
  }
});

export const {
  setAllActor,
  addOneActor,
  setOneActor,
  removeOneActor,
  updateOneActor
} = actorSlice.actions;
export default actorSlice.reducer;
