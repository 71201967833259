import ForgotPasswordForm from 'components/modules/auth/ForgotPasswordForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const ForgotPassword = () => {
  document.title = process.env.REACT_APP_NAME + ' | Forgot Password';
  return (
    <AuthCardLayout>
      <ForgotPasswordForm />
    </AuthCardLayout>
  );
};

export default ForgotPassword;
