import api from '../../utils/api';

const tenantEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/api/auth/tenants/profileSetting`;
const userEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/api/auth/tenants/myProfile`;

const headers = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

const fetchTenantProfile = () => {
  return api.get(`${tenantEndpoint}/view`);
};

const updateTenantProfile = (data: FormData) => {
  return api.post(`${tenantEndpoint}`, data, headers);
};

const fetchUserProfile = () => {
  return api.get(`${userEndpoint}/get`);
};

const updateUserProfile = (data: FormData) => {
  return api.post(`${userEndpoint}/updateProfile`, data, headers);
};

export default {
  fetchTenantProfile,
  updateTenantProfile,
  fetchUserProfile,
  updateUserProfile
};
