import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';

import { lazy, Suspense } from 'react';
import PhoenixLoader from 'components/common/PhoenixLoader';
import AuthGuard from '../utils/route-guard/AuthGuard';

const EncounterFormPage = lazy(
  () => import('components/forms/encounter-management')
);

const Dashboard = lazy(() => import('pages/dashboard'));
const ActivityTypeDashboardPage = lazy(
  () => import('pages/dashboard/ActivityTypeDashboardPage')
);
const Location = lazy(() => import('pages/mdm/LocationPage'));
const ActivityTypeSchools = lazy(
  () => import('pages/dashboard/ActivityType/index')
);
const ActivityTypePatients = lazy(
  () => import('pages/dashboard/ActivityType/ActivityTypePatients')
);
const OHEPage = lazy(() => import('pages/ohe'));
const CSIPage = lazy(() => import('pages/csi'));
const HealthCenterManagementPage = lazy(
  () => import('pages/health-center-management')
);
const BookingManagementPage = lazy(() => import('pages/booking-management'));
// const EncounterPage = lazy(
//   () => import('pages/encounter-management/EncounterPage')
// );

const ActivityTypeRoute = `/activity-type/:activityTypeId/:activityTypeVenueTypeId`;
const ActivityTypeSchoolsRoute = `${ActivityTypeRoute}/actor/:actorId/schools`;
const ActivityTypePatientsRoute = `${ActivityTypeSchoolsRoute}/:venueId/patients`;

const MainRoutes: RouteObject = {
  path: '/',
  element: (
    <MainLayoutProvider>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </MainLayoutProvider>
  ),
  children: [
    {
      path: '/',
      index: true,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <Dashboard />
        </Suspense>
      )
    },

    // TODO: find a better way to handle this
    {
      path: ActivityTypeRoute,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <ActivityTypeDashboardPage />
        </Suspense>
      )
    },

    {
      path: ActivityTypeSchoolsRoute,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <ActivityTypeSchools />
        </Suspense>
      )
    },

    {
      path: ActivityTypePatientsRoute,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <ActivityTypePatients />
        </Suspense>
      )
    },
    {
      path: 'activity-type/:activityTypeId/encounter-tab',
      index: true,
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <EncounterFormPage />
        </Suspense>
      )
    },

    {
      path: '/ohe',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <OHEPage />
        </Suspense>
      )
    },
    {
      path: '/health-center-management',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <HealthCenterManagementPage />
        </Suspense>
      )
    },

    {
      path: '/csi',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <CSIPage />
        </Suspense>
      )
    },

    {
      path: '/booking-management',
      element: (
        <Suspense fallback={<PhoenixLoader />}>
          <BookingManagementPage />
        </Suspense>
      )
    },

    // children: [
    //   {
    //     path: 'schools',
    //     element: (
    //       <Suspense fallback={<PhoenixLoader />}>
    //         <ActivityTypeSchools />
    //       </Suspense>
    //     )
    //   },
    //   {
    //     path: 'patients',
    //     element: (
    //       <Suspense fallback={<PhoenixLoader />}>
    //         <ActivityTypePatients />
    //       </Suspense>
    //     )
    //   }
    // ]
    {
      path: '/mdm',
      children: [
        {
          path: 'location',
          index: true,
          element: (
            <Suspense fallback={<PhoenixLoader />}>
              <Location />
            </Suspense>
          )
        }
      ]
    }
  ]
};
export default MainRoutes;
