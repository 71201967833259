import { createSlice } from '@reduxjs/toolkit';
import { Venue } from '../../../types/actorManagement';
import { MetaType } from '../../../types';

interface VenueState {
  venues: Venue[];
  venue: Venue;
  meta: MetaType;
}

const initialState: VenueState = {
  venues: [],
  venue: <Venue>{},
  meta: <MetaType>{}
};

const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    setAllVenue(state, action) {
      state.venues = action.payload.results || [];
      state.meta = action.payload.meta || <MetaType>{};
    },
    addOneVenue(state, action) {
      state.venues = [...state.venues, action.payload.row];
    },
    UpdateOneVenue(state, action) {
      state.venues = state.venues.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    setOneVenue(state, action) {
      state.venue = action.payload.row;
    },
    removeOneVenue(state, action) {
      state.venues = state.venues.filter(item => item.id !== action.payload.id);
    }
  }
});

export const {
  setAllVenue,
  addOneVenue,
  setOneVenue,
  removeOneVenue,
  UpdateOneVenue
} = venueSlice.actions;
export default venueSlice.reducer;
