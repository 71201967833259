import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// types
import useAuthHook from '../../hooks/useAuthHook';

// ==============================|| AUTH GUARD ||============================== //
export type GuardProps = {
  children: ReactElement | null;
};

const AuthGuard = ({ children }: GuardProps) => {
  const { isAuthenticated, tenant } = useAuthHook();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const appEnv = process.env.REACT_APP_APP_ENV || 'local';
    const mainDomain = process.env.REACT_APP_MAIN_DOMAIN || null;
    const origin = window.location.origin;
    if (!isAuthenticated) {
      if (appEnv.toLowerCase() != 'local') {
        if (mainDomain && origin != mainDomain) {
          window.location.href = mainDomain + '/auth/sign-in';
        }
      }
      window.location.href = '/auth/sign-in';
      // navigate('/auth/sign-in', {
      //   state: {
      //     from: location.pathname
      //   },
      //   replace: true
      // });
    } else {
      if (appEnv.toLowerCase() != 'local') {
        if (mainDomain && origin === mainDomain) {
          if (!tenant?.redirect) {
            // logout().catch(() => console.log('logout'));
            console.log('Not found redirect url');
          }
        }
      }
    }
  }, [isAuthenticated, navigate, location]);

  return children;
};

export default AuthGuard;
